@charset "utf-8";

.sitemenu {
  display: none;
  position: fixed;
  z-index: 20;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  color: $textBlack;
  //display: block;//DEV
  @media screen and (min-width: $breakPointSml + 1 + px){
    display: none!important;

  }
  &__overlay {
    position: absolute;
    top:0;
    left:0;
    width: 100vw;
    height: 100%;
    background-color: $white;
  }
  .l-container__inner {
  }

  &__content {
    position: relative;
    background-color: $white;
    height: 100%;
    width: 100%;
    padding: 0 28px 60px;
    overflow-x: hidden;
    overflow-y: scroll;
  }
  &__storelink {
    position: absolute;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    top: 13px;
    left: 20px;
    margin-bottom: 0;
    font-size: 13px;
    li {
      margin-right: 20px;
      a {
        display: block;
      }
    }
  }
  &__logo {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 176px;
    background-color: $white;
    a {
      display: block;
      margin-top: 86px;
    }
  }
  &__close {
    display: block;
    width: 48px;
    height: 46px;
    //background-color: #F4F4F4;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    margin: 0 -28px -46px auto;
    z-index: 2;
    &::before,
    &::after {
      content:'';
      display: block;
      background-color: #111;
      position: absolute;
      width: 16px;
      height: 1px;
      position: absolute;
      top: 24px;
      left: 50%;
      margin-left: -8px;
    }
    &::before {
      transform: rotate(45deg);
    }
    &::after {
      transform: rotate(-45deg);
    }
  }
  &__inner {
    position: relative;
    background-color: $white;
    @media screen and (max-width: $breakPointSml + px){
      //margin-left: 85px;
    }
    & > .menunav {
      border-top: 1px solid $borderColor;
    }
  }
  &__nav {
    margin-bottom: 0;
  }
  &__sns {
    margin-top: 28px;
    padding: 0 5px;
    .c-iconlist__item + .c-iconlist__item {
      margin-left: 16px;
    }
  }


}


.menunav {
  margin-top: 0;
  margin-bottom:0;
  font-size: 16px;
  &__item {
    //margin-bottom: 5px;
    border-bottom: 1px solid $borderColor;
    &:first-child {
      //border-top: 1px solid $borderColor;
    }
    //子グループがあるリストのルート要素
    &--toggleroot {
      border-top: none!important;
      //内包するリストを非表示にする
      & ul {
        display: none;
        li {
          border-color: #F2F2F2;
          &:not(.menunav__item--has-child){
            border-bottom: none;
          }
          //子グループを内包する要素 height: 44px; しない要素: height: 40px
          //子グループは全て右に10pxずらす
          a {
            padding: 7px 20px 5px 25px;
            min-height: 40px;
          }
          &.menunav__item--has-child {
            & > a {
              padding: 9px 20px 7px 25px;
              min-height: 44px;
            }
          }
        }
      }
      //内包するリストのうち、直下のリスト（親グループ）はボーダーをもつ
      & > ul {
        //display: block;
        & ul {
          color: $textMenuColor;
        }
        & > li {
          border-bottom: 1px solid #F2F2F2;
          &:last-child {
            border-bottom: none;
          }
        }
      }
    }
    //子グループがあればボーダーがつく
    &--has-child {
      border-top: 1px solid $borderColor;
      border-bottom: 1px solid $borderColor;
      //子グループをもつリストが隣接した時にボーダが重なるのを解消
      & + .menunav__item--has-child {
        border-top: none;
      }
      //CATEGORYグループのみ、CATEGORYへのリンクを非表示
      &.is-category {
        border-top: 1px solid transparent;
        & > a {
          display: none;
        }
        //リンクが非表示になると上のボーダーが重なるのを解消
        & > .menunav {
          & > li:first-child {
            border-top: none;
          }
        }
      }
      //+マーク
      & > a {
        &::before,
        &::after {
          content:'';
          display: block;
          position: absolute;
          background-color: #444;
          top: 50%;
          transition: opacity .2s ease-out;
        }
        &::before {
          width: 11px;
          height: 1px;
          margin-top: 0;
          right: 13px;
        }
        &::after {
          margin-top: -5px;
          width: 1px;
          height: 11px;
          right: 18px;
        }
      }
    }
    //.is-open が着くと子グループを表示。初期表示に使用
    &.is-open {
      & > ul {
        display: block;
      }
      & > a {
        &::after {
          opacity: 0;
        }
      }
    }


    a {
      display: block;
      min-height: 48px;
      padding: 11px 15px 9px;
      position: relative;
    }

  }
}
