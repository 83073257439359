@charset "utf-8";

.heroslider-container {
  padding-bottom: 0;
  overflow: hidden;
}
.heroslider {
  margin-bottom: 24px;
  overflow: visible;
  &__item {
    -webkit-backface-visibility: hidden;
    -webkit-transform: translate3d(0,0,0);
    width: 1030px;
    height: 618px;
    //margin: 0 10px;
    @media screen and (max-width: 1030 + px){
      width: 100%;
      height: auto;
      min-height: 540px;
    }
    @media screen and (max-width: $breakPointSml + px){
      width: 100%;
      height: 468px;
      height: 125vw;
      margin: 0;
      min-height: initial;
    }
    a {
      display: block;
      width: 100;
      height: 100%;
      transition: opacity .2s ease-out;
      &:hover {
        opacity: .7;
      }
    }
    figure {
      display: block;
      width: 100;
      height: 100%;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  .swiper-wrapper {
    //-webkit-transform-style: preserve-3d;
    transition-timing-function: $easeInOutCubic;
    @media screen and (max-width: $breakPointSml + px){
      transition-timing-function: ease-out;
    }
  }
  &__nav {
    max-width: 1030px;
    margin: 0 auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .c-slidepagination {
    bottom: -22px;
    @media screen and (max-width: $breakPointSml + px){
      bottom: -12px;
    }
    .swiper-pagination-bullet {
      @media screen and (max-width: $breakPointSml + px){
        height: 1px;
      }
    }
  }
  .c-slidearrow {
    &.swiper-button-prev {
      left: 0;
    }
    &.swiper-button-next {
      right: 0;
    }
    @media screen and (max-width: 499 + px){
      display: none;
    }
  }

}
