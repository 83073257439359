@charset "utf-8";


.base-content {
  //color: $black;
  word-break: break-all;
  h2, h3, h4, h5, h6 {
    line-height: 1.5;
    &:first-child {
      margin-top: 0;
    }
  }
  h2 {
    margin-top: 2.1em;
    margin-bottom: 1.4em;
    font-size: 24px;
    @media screen and (max-width: $breakPointSml + px) {
      font-size: 18px;
      margin-bottom: 1.7em;
    }
    & + h3, & + h4, &+ h6 {
      margin-top: 1.8em;
    }
  }
  h3 {
    margin-top: 1.8em;
    margin-bottom: 1.3em;
    padding-left: 13px;
    position: relative;
    font-size: 20px;
    @media screen and (max-width: $breakPointSml + px) {
      margin-top: 2em;
      margin-bottom: 1.4em;
      font-size: 16px;
    }
    &:before {
      content:'';
      display: block;
      width: 2px;
      height: calc(100% - 8px);
      background-color: $textBlack;
      position: absolute;
      top: 4px;
      left: 0;
    }
    & + h3, & + h4, &+ h6 {
      margin-top: 1.8em;
    }

  }
  h4 {
    margin-bottom: 2em;
    font-weight: bold;
    font-size: 14px;
  }
  h5, h6 {
    margin-top: 2em;
    margin-bottom: 2em;
  }
  a {
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
  .c-btn {
    margin-top: 2em;
    margin-bottom: 2em;
    @media screen and (max-width: $breakPointSml + px){
      margin-top: 1.5em;
    }
  }
  figure {
    margin-bottom: 2em;
  }
  p {
    margin-bottom: 1.4em;
    @media screen and (max-width: $breakPointSml + px){
      margin-bottom: 1em;
    }
    &:last-child {
      margin-bottom: 0;
    }
    img:first-child {
      margin-top: 0;
    }
    img:last-child {
      margin-bottom: 0;
    }
    &.caption {
      margin-top: -18px;
      margin-bottom: 1.7em;
      font-size: 13px;
      line-height: 1.6;
      @media screen and (max-width: $breakPointSml + px) {
        margin-top: -15px;
        margin-bottom: 2em;
        font-size: 12px;
      }
    }
  }
  img {
    margin-bottom: 2em;
    @media screen and (max-width: $breakPointSml + px){
      margin-bottom: 1.4em;
    }
  }
  figure {
    margin-bottom: 2em;
    @media screen and (max-width: $breakPointSml + px){
      margin-bottom: 1.4em;
    }
  }
  .size75 img{
    width: 75%;
  }
  .size50 img{
    width: 50%;
  }
  .size25 img{
    width: 25%;
  }
  .alignright img {
    float: right;
  }
  .aligncenter {
    text-align: center;
  }
  .box {
    border-radius: 0;
    margin-top: 2.2em;
    margin-bottom: 2.4em;
    padding: 22px 27px 22px;
    @media screen and (max-width: $breakPointSml + px) {
      padding: 19px 24px 18px;
    }
    h2 {
      font-size: 22px;
      font-weight: normal;

    }
    h3:not(:first-child) {
      margin-top: 2.7em;
    }
    & + h2 {
      margin-top: 2.4em;
    }
    & + h3, & + h4, & + h6 {
      margin-top: 2.8em;
    }
    p:last-child {
      margin-bottom: 0!important;
    }
  }
  figcaption, .note{
    margin-top: -0.8em;
    font-size: 13px;
  }
  figcaption {
    //line-height: 1.6;
    //padding-top: 9px;
    @media screen and (max-width: $breakPointSml + px) {
      //padding-top: 6px;
    }
  }
  .box + .caption {
    margin-top: -20px;
  }
  hr {
    margin-top: 49px;
    margin-bottom: 53px;
    border-color: #979797;
    @media screen and (max-width: $breakPointSml + px) {
      margin-top: 42px;
      margin-bottom: 43px;
    }
  }
}
