@charset "utf-8";

.l-main[data-page="pages"]{
  .c-pagetitle {
    margin-top: -8px; //line-height分戻す
    margin-bottom: 66px;
    @media screen and (max-width: $breakPointSml + px){
      margin-bottom: 53px;
    }
  }
}
