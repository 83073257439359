@charset "utf-8";

.tickersection {
  position: relative;
  z-index: 11;
  background-color: $bgColor;
}

.l-main[data-page="home"]{
  padding-top: 0;
  padding-bottom: 0;
    @media screen and (max-width: $breakPointSml + px){
      padding-top: 0;
  }
}

.homeheader {
  background-color: #7F7F7F;
  width: 100%;
  height: 69px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid $white;
  line-height: 1;
  display: none;
  @media screen and (max-width: $breakPointSml + px){
    display: flex;
  }
}

.homesection {
  padding-bottom: 72px;
  @media screen and (max-width: $breakPointSml + px){
    padding-bottom: 60px;
  }
  & + & {
    padding-top: 96px;
    @media screen and (max-width: $breakPointSml + px){
      padding-top: 66px;
    }
  }
  &__newin {
    padding-top: 96px;
    @media screen and (max-width: $breakPointSml + px){
      padding-top: 53px;
    }
    .c-slidearrow {
      top: calc((100% - 100px)/2);
      margin-top: -22px;
      &.swiper-button-prev {
        left: 0;
      }
      &.swiper-button-next {
        right: 0;
      }
      @media screen and (max-width: 499 + px){
        display: none;
      }
    }
    .btnarea {
      margin-top: 50px;
      margin-bottom: 0;
      @media screen and (max-width: $breakPointSml + px){
        margin-top: 32px;
      }
    }
  }
  &__feature {
    background-color: $bgColor;
    .c-featureslider {
      .c-slidearrow {
        &.swiper-button-prev {
          left: 0;
        }
        &.swiper-button-next {
          right: 0;
        }
      }
    }
  }
  &__category {
    padding-bottom: 32px;
    @media screen and (max-width: $breakPointSml + px){
      padding-bottom: 24px;
    }
  }
  &__designers {
    background-color: $bgColor;
    .c-designersslider {
      .c-slidearrow {
        &.swiper-button-prev {
          left: 0;
        }
        &.swiper-button-next {
          right: 0;
        }
      }
    }
    .btnarea {
      margin-top: 56px;
      margin-bottom: 0;
      @media screen and (max-width: $breakPointSml + px){
        margin-top: 37px;
      }
    }
  }
  &__news {
    .btnarea {
      margin-top: 20px;
      margin-bottom: 0;
      @media screen and (max-width: $breakPointSml + px){
        margin-top: 13px;
      }
    }
  }

}


