@charset "utf-8";

.l-main[data-page="detail"]{
  padding-bottom: 0;
  @media screen and (max-width: $breakPointSml + px){
    padding-top: 41px;
  }

  .l-breadcrumb {
    position: absolute;
    top: 86px;
    max-width: calc(100% - 20px);
    @media screen and (max-width: $breakPointSml + px){
      top: 64px;
      left: 12px;
    }
  }
  .l-othersection {
    margin-top: 110px;
    @media screen and (max-width: $breakPointSml + px){
      margin-top: 96px;
    }
  }
  .base-content {
    //font-family: "Hiragino Sans", "ヒラギノ角ゴシック", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", Meiryo, Helvetica, Arial, system-ui, sans-serif;
    h2, h3, h4, h5, h6 {
      //font-family: "ヒラギノ明朝 Pro W3", Hiragino Mincho Pro, "游明朝", "YuMincho", "Yu Mincho", "MS PMincho", "Times New Roman", serif;
    }
    .c-btn {
      font-family: garamond-premier-pro, "Hiragino Mincho ProN", "YuMincho", "Yu Mincho", "MS PMincho", "Times New Roman", serif;
      color: $textBaseColor;
      //font-family: "ヒラギノ明朝 Pro W3", Hiragino Mincho Pro, "游明朝", "YuMincho", "Yu Mincho", "MS PMincho", "Times New Roman", serif;
      //font-family: 'Source Sans Pro',"Hiragino Sans", "ヒラギノ角ゴシック", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", Meiryo, Helvetica, Arial, system-ui, sans-serif;
    }
  }
}

.productcontainer {
  position: relative;
  @media screen and (max-width: $breakPointSml + px){
    //display: block;
  }
  &__img {
    padding-right: 52px;
    width: 61.27%;
    @media screen and (max-width: $breakPointTablet-Sml + px){
      padding-right: 0;
      width: calc(100% + 40px);
      margin-left: -20px;
    }
    .c-slidearrow {
      /*
      width: 36px;
      height: 53px;
      background-size: 15px 15px;
      background-position: left 12px top 18px;
      @media screen and (max-width: $breakPointSml + px){
        width: 28px;
        height: 52px;
        background-size: 15px 15px;
        background-position: left 8px top 18px;
      }*/
    }
    .swiper-button-prev.c-slidearrow {
      left: 0;
    }
    .swiper-button-next.c-slidearrow {
      right: 0;
    }
  }
  &__meta {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 38.731%;
    @media screen and (max-width: $breakPointTablet-Sml + px){
      width: 100%;
      padding-top: 33px;
      position: static;
    }
  }
  &__detail {
    padding-right: 52px;
    width: 61.27%;
    margin-top: 58px;
    @media screen and (max-width: $breakPointTablet-Sml + px){
      width: 100%;
      margin-top: 48px;
      padding-right: 0;
    }
  }
}



.productmeta {
  &__inner {
    position: -webkit-sticky;
    position: sticky;
    top: 144px;
    padding-top: 10px;
    @media screen and (max-width: $breakPointSml + px){
      position: static;
      padding-top: 0;
    }
  }
  &__brand {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 28px;
    line-height: 1.6;
    @media screen and (max-width: $breakPointSml + px){
      margin-top: 0;
      font-size: 21px;
    }
  }
  &__name {
    margin-top: 0;
    margin-bottom: 8px;
    font-size: 15px;
    color: $textBaseColor;
    @media screen and (max-width: $breakPointSml + px){
      font-size: 13px;
    }
  }
  &__price {
    margin-bottom: 10px;
    font-size: 15px;
    @media screen and (max-width: $breakPointSml + px){
      font-size: 13px;
    }
  }
  &__spec {
    margin-bottom: 36px;
    @media screen and (max-width: $breakPointSml + px){
      margin-bottom: 26px;
      font-size: 12px;
    }
    dl {
      margin-top: 0;
      dt {
        width: 132px;
        float: left;
        color: #777777;
        @media screen and (max-width: $breakPointSml + px){
          width: 100px;
        }
      }
      dd {
        padding-top: 2px;
        padding-left: 132px;
        margin-bottom: 3px;
        color: $textBlack;
        line-height: 1.4;
        min-height: 21px;
        @media screen and (max-width: $breakPointSml + px){
          min-height: 20px;
          margin-bottom: 0;
          padding-left: 100px;
        }
        a {
          text-decoration: underline;
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }
  &__cart {
    margin-bottom: 0;
    .shoppingguide {
      font-size: 13px;
      color: #777777;
    }
  }
  .c-note {
    margin-top: 22px;
    font-size: 12px;
    line-height: 1.5;
    @media screen and (max-width: $breakPointSml + px){
      margin-top: 19px;
      font-size: 10px;
    }
  }
}

.productdetail {
  &__desc {
    padding-bottom: 60px;
    border-bottom: 1px solid #979797;
    @media screen and (max-width: $breakPointSml + px){
      padding-bottom: 42px;
    }
  }
  &__share {
    margin-top: 32px;
  }
}

.btnarea,
#btn_cart {
  margin-top: 20px;
}
.btn-addcart {
  border-radius: 0px;
  letter-spacing: 0.05em;
  @media screen and (max-width: $breakPointSml + px){
    height: 48px;
  }
}
.optionWrp {
  width: 100%;
  tr {
    margin-bottom: 20px;
  }
  input[type="text"] {
    border-radius: 3px;
    border: 1px solid #ccc;
    padding: 4px 6px;
    font-size: 16px;
    @media screen and (max-width: $breakPointSml + px){
      height: 30px;
    }
  }
  .cartNum {
    height: 20px;
    display: inline-block;
    width: 134px;
    @media screen and (max-width: $breakPointSml + px){
      width: calc(100% - 20px);
    }
  }
  .itemQu {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .updw {
    margin: 0;
    padding-left: 4px;
    vertical-align: middle;
    display: inline-block;
    font-size: 13px;
    line-height: 1;
    width: 20px;
    text-align: center;
    @media screen and (max-width: $breakPointSml + px){
      width: 20px;
    }
  }
  .option-list__select {
    width: 100%;
    border: 1px solid #ccc;
    background-color: #fff;
    height: 20px;
    line-height: 20px;
    border-radius: 3px;
    padding: 4px 6px;
    font-size: 16px;
    @media screen and (max-width: $breakPointSml + px){
      height: 30px;
    }
  }

}
