@charset "utf-8";

//================================
//PARTS
//================================
.l-main {
  padding-top: 80px;
  padding-bottom: 112px;
  @media screen and (max-width: $breakPointSml + px){
    padding-top: 64px;
    padding-bottom: 73px;
  }
}



.l-inner {
  max-width: 1332px;
  padding-right: 36px;
  padding-left: 36px;
  margin-right: auto;
  margin-left: auto;
  @media screen and (max-width: $breakPointTablet + px){

  }
  @media screen and (max-width: $breakPointSml + px){
    padding-right: 20px;
    padding-left: 20px;
  }
  &--medium {
    max-width: 1116px;
  }
}


.l-container{

  @media screen and (max-width: $breakPointTablet + px){

  }
  &__main {
    width: calc(100% - 25px);
    max-width: 810px;
    padding-bottom: 40px;
    @media screen and (max-width: $breakPointTablet + px){
      width: 100%;
    }
    //
  }
  &__bottom {
  }
}

.l-historysection {
  position: relative;
  padding-top: 44px;
  padding-bottom: 62px;
  .l-col {
    margin-top: 66px;
  }
}

.l-breadcrumb__top {
  margin-top: 16px;
  margin-bottom: 43px;
}

.l-sortnav {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.l-shopnav {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 0;
  font-size: 11px;
  &__item {
    & + .l-shopnav__item {
      margin-left: 21px;
    }
    &--search {
      margin-right: 10px;
    }
  }

}


.l-categorypagehead {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  @media screen and (max-width: $breakPointTablet-Sml + px){
    display: block;
  }
  &__metabox {
    width: calc(50% - 18px);
    padding: 70px 0 0;
    @media screen and (max-width: $breakPointTablet + px) and (min-width: $breakPointSml + px){
      padding-top: 53px;
      font-size: 13px;
    }

    @media screen and (max-width: $breakPointTablet-Sml + px){
      display: block;
      order: 2;
      width: 100%;
      max-width: 100%;
      padding: 32px 0 0;
    }
    p {
      margin-bottom: 1.4em;
      line-height: 1.7;
      @media screen and (max-width: $breakPointTablet-Sml + px){
        margin-bottom: 1.2em;
      }
    }
    .c-pagetitle__en {
      @media screen and (max-width: $breakPointTablet + px) and (min-width: $breakPointSml + px){
        font-size: 27px;
      }
    }
  }
  &__imgbox {
    order: 2;
    width: calc(50% - 18px);
    @media screen and (max-width: $breakPointTablet-Sml + px){
      width: calc(100% + 72px);
      margin-left: -36px;
      order: 1;
    }
    @media screen and (max-width: $breakPointSml + px){
      width: calc(100% + 40px);
      margin-left: -20px;

    }
    img {

    }
  }
}


.l-archivepagehead {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  //flex-wrap: wrap;
  @media screen and (max-width: $breakPointTablet-Sml + px){
    display: block;
  }
  &__title {
    margin-bottom: 3px;
    padding-right: 28px;
    @media screen and (max-width: $breakPointTablet-Sml + px){
      margin-bottom: 2px;
    }
    @media screen and (max-width: $breakPointSml + px){

    }
  }
  &__nav {
    margin-top: 11px;
    flex: 1;
    @media screen and (max-width: $breakPointTablet-Sml + px){
      margin-top: 8px;
      width: 100%;
    }
    .c-groupchildlist {
      @media screen and (max-width: $breakPointTablet-Sml + px){
        width: calc(100% + 20px);
      }
      @media screen and (max-width: $breakPointSml + px){

      }
    }
  }
  .brandCap {
    display: none;//旧レイアウトの要素
  }
}


.l-groupdesc {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  @media screen and (max-width: $breakPointTablet-Sml + px){
    flex-direction: column;
  }
  &__metabox {
    flex: 1;
    line-height: 1.7;
    &.base-content p {
      margin-bottom: 1em;
    }
    @media screen and (max-width: $breakPointTablet + px) and (min-width: $breakPointSml + px){
      font-size: 13px;
    }
    @media screen and (max-width: $breakPointTablet-Sml + px){
      order: 2;
      width: 100%;
    }
  }
  &__imgbox {
    order: 2;
    width: 50%;
    padding-left: 18px;
    margin-left: 18px;
    img {
      margin-top: 4px;
      @media screen and (max-width: $breakPointTablet-Sml + px){
        margin-top: 0;
      }
    }
    @media screen and (max-width: $breakPointTablet-Sml + px){
      order: 1;
      width: 100%;
      margin-bottom: 20px;
      padding-left: 0;
      margin-left: 0;
    }
  }
}

.l-othersection {
  margin-top: 112px;
  @media screen and (max-width: $breakPointSml + px){
    margin-top: 96px;
  }
}



.l-col {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 0;
  margin-bottom: 0;
  word-break: break-all;
  &__item {
    transition: opacity .8s ease-out;
    &.is-hide {
      opacity: 0;
    }
    &--col-2 {
      width: calc(50% - 15px);
      margin-left: 30px;
      &:nth-child(2n-1) {
        margin-left: 0;
      }
      &:nth-child(n+3) {
        @media screen and (min-width: $breakPointSml + 1 + px){
          margin-top: 87px;
        }

      }
      @media screen and (max-width: $breakPointSml + px){
        width: 100%;
        margin-left: 0%;
        & + & {
          margin-top: 45px;
        }
      }
    }
    &--col-3 {
      width: calc(33.33% - 24px);
      margin-left: 36px;
      margin-bottom: 30px;
      @media screen and (min-width: 1332 + px){
        width: calc(33.334% - 24px);
      }
      &:nth-child(3n-2) {
        @media screen and (min-width: $breakPointSml + 1 + px){
          margin-left: 0;
        }
      }
      &:nth-child(n+4) {
        @media screen and (min-width: $breakPointSml + 1 + px){
          margin-top: 25px;
        }
      }
      @media screen and (max-width: $breakPointSml + px){
        width: calc(50% - 5px);
        margin-left: 10px;
        &:nth-child(2n-1) {
          margin-left: 0;
        }
        &:nth-child(n+3) {
          //margin-top: 30px;
        }
      }
    }
    &--col-4 {
      width: calc(25% - 27px);
      margin-left: 36px;
      margin-bottom: 23px;
      /*
      @media screen and (max-width: $breakPointTablet + px) and (min-width: $breakPointSml + 1 + px){
        width: 31.74%;
        margin-left: 2.388%;
        &:nth-child(3n-2) {
          @media screen and (min-width: $breakPointSml + 1 + px){
            margin-left: 0;
          }
        }
      }*/
      &:nth-child(4n-3) {
        margin-left: 0;
      }
      &:nth-child(n+5) {
        //margin-top: 35px;
      }
      @media screen and (max-width: $breakPointSml + px){
        width: calc(50% - 7.5px);
        margin-left: 15px;
        &:nth-child(2n-1) {
          margin-left: 0;
        }
      }
    }
    &--col-6 {
      width: 180px;
      margin-left: 8px;
      &:nth-child(6n-5) {
        margin-left: 0;
      }
      .c-card__metabox {
        padding-top: 7px;
      }
      @media screen and (max-width: $breakPointSml + px){
        width: calc(50% - 5px);
        margin-left: 10px;
        &:nth-child(6n-5) {
          margin-left: 10px;
        }
        &:nth-child(2n-1) {
          margin-left: 0;
        }
        &:nth-child(n+3) {
          margin-top: 30px;
        }
      }
    }
    &--col-1-2 {
      margin-bottom: 0;
      &:nth-child(1) {
        width: 25.598%;
        @media screen and (max-width: $breakPointSml + px){
          width: 100%;
        }
      }
      &:nth-child(2) {
        width: 74.402%;
        @media screen and (max-width: $breakPointSml + px){
          width: 100%;
        }
      }
    }
  }
}


.l-productlist {
  .l-col__item--col-4 {
    margin-bottom: 38px;
  }
  @media screen and (max-width: $breakPointTablet + px) and (min-width: $breakPointTablet-Sml + 1 + px){
    .l-col__item--col-4 {
      width: calc(33.3% - 24px);
      &:nth-child(4n-3){
        margin-left: 36px;
      }
      &:nth-child(3n-2){
        margin-left: 0;
      }
    }
  }
  @media screen and (max-width: $breakPointTablet-Sml + px){
    width: calc(100% + 16px);
    margin-left: -8px;
    .l-col__item--col-4 {
      width: calc(50% - 6px);
      margin-bottom: 26px;
      margin-left: 12px;
      &:nth-child(2n-1){
        margin-left: 0;
      }
    }
  }
}

.l-othersection {
@media screen and (max-width: $breakPointTablet + px) and (min-width: $breakPointTablet-Sml + 1 + px){
  .l-productlist {
    .l-col__item--col-4 {
      &:nth-child(4) {
        display: none;
      }
    }
  }
}

}

.l-othersection {
  &__tab {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    font-size: 21px;
    line-height: 1.6;
    color: $textColorGrey;
    @media screen and (max-width: $breakPointSml + px){
      padding-right: 12px;
      padding-left: 12px;
      font-size: 18px;
      letter-spacing: 0em;
    }
  }
  &__tabitem {
    width: 33.3%;
    max-width: 288px;
    height: 56px;
    background: $bgColor;
    margin: 0 4px;
    text-align: center;
    transition: background .2s ease-out;
    @media screen and (max-width: $breakPointSml + px){
      margin: 0 2.5px;
    }
    &.is-current,
    &:hover {
      background-color: #F9F9F9;
    }
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      padding-top: 3px;
      @media screen and (max-width: $breakPointSml + px){
        padding-top: 4px;
      }
    }
  }
  &__content {
    padding-top: 72px;
    padding-bottom: 28px;
    background-color: #F9F9F9;
    @media screen and (max-width: $breakPointSml + px){
      padding-top: 28px;
      padding-bottom: 45px;
    }
  }
  &__contentitem {
    display: none;
    &:first-child {
      display: block;
    }
  }
}

.l-brandlist {
  &__item {
    a {
      display: block;
      transition: opacity .2s ease-out;
      &:hover {
        opacity: .7;
      }
    }
  }
  &__imgbox {
    img {
      width: 100%;
    }
  }
  &__metabox {
    padding: 10px 10px 0;
    h2 {
      margin: 0;
    }
  }
  &__title {
    text-align: center;
    font-size: 16px;
    @media screen and (max-width: $breakPointSml + px){
      font-size: 14px;
    }
  }
}



.l-floatnav {
  width: 100%;
  height: calc(100vh - #{$headerPCHeight});
  overflow-y: scroll;
  overflow-x: hidden;
  display: none;
  &__overlay {
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: calc(100vh - #{$headerPCHeight});
    background-color: rgba($black, .8);
    z-index: 1;
  }
  &__content {
    position: relative;
    z-index: 2;
    background-color: $white;
    min-height: 100px;
    font-size: 16px;
    padding: 30px 0 26px;
  }
  .l-inner {
    max-width: 1272px;
  }
  &__container {
    width: min-content;
    max-width: 100%;
    position: relative;
    margin-right: auto;
    margin-left: auto;
    padding-bottom: 52px;
  }
  &__wrapper {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: min-content;
    max-width: 100%;
    position: relative;
    margin-right: auto;
    margin-left: auto;
    padding-bottom: 52px;
  }
  &__item {
    width: calc(20vw);

    padding-right: 28px;
    max-width: 240px;
  }
  &__title {
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 16px;
    @media screen and (max-width: $breakPointTablet-Sml + px){
      font-size: 15px;
    }
    a {
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
      &.nolink {
        text-decoration: none;
        pointer-events: none;
      }
    }
  }
  &__list {
    color: $textMenuColor;
    @media screen and (max-width: $breakPointTablet-Sml + px){
      font-size: 14px;
    }
    li {
      margin-bottom: -1px;
      a {
        display: inline-block;
        transition: opacity .2s ease-out;
        &:hover {
          opacity: .7;
        }
      }
    }
  }
  &__sns {
    position: absolute;
    bottom: 0;
    left: 0;
  }
  &__close {
    width: 28px;

    height: 28px;
    border-radius: 14px;
    display: block;
    border: 1px solid transparent;
    position: -webkit-sticky;
    position: sticky;
    top: 30px;
    float: right;
    margin-right: 50px;
    transition: border .2s ease-out;
    z-index: 2;
    font-size: 0;
    @media screen and (max-width: $breakPointTablet + px){
      margin-right: 20px;
    }
    &:hover {
      //opacity: .7;
      border-color: $borderColor;
    }
    &:before,
    &:after {
      content:'';
      display: block;
      width: 13px;
      height: 1px;
      background-color: $textBlack;
      position: absolute;
      top: 50%;
      right: 7px;
    }
    &:before {
      transform: rotate(-45deg);
    }
    &:after {
      transform: rotate(45deg);
    }
  }
}


.l-newslist {
  @media screen and (min-width: $breakPointTablet + 1 + px){
    .l-col__item--col-3 {
      &:nth-child(4) {
        display: none;
      }
    }
  }
  @media screen and (max-width: $breakPointTablet + px) and (min-width: $breakPointSml + 1 + px){
    .l-col__item--col-3 {
      width: calc(50% - 18px);
      margin-left: 36px;
      margin-top: 0;
      &:nth-child(3n-2){
        margin-left: 0;
      }
      &:nth-child(2n){
        margin-left: 36px;
      }
      &:nth-child(2n-1){
        margin-left: 0;
      }
    }

  }
  @media screen and (max-width: $breakPointSml + px){
    width: calc(100% + 8px);
    margin-left: -4px;
    .l-col__item--col-3 {
      width: calc(50% - 5.5px);
      margin-left: 11px;
      margin-top: 0;
      margin-bottom: 16px;
    }
    .l-col__item--col-3:nth-child(2n-1) {
      margin-left: 0;
    }
  }
}
