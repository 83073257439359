@charset "utf-8";

.c-gnav {
  margin: 0;
  font-size: 14px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  color: $textBlack;
  letter-spacing: 0.12em;
  @media screen and (max-width: $breakPointTablet-Sml + px){
    letter-spacing: initial;
  }
  &__item {
    height: 100%;
    & + .c-gnav__item {
      margin-left: 47px;
    }
    & > a {
      display: block;
      height: 100%;
      padding-top: 26px;
      transition: opacity .2s ease-out;
      &:hover,
      &.is-current {
        opacity: .6;
      }
    }
  }
}

body.is-floatnav-open {
  .c-gnav {
    a.js-floatnav-toggle {
      opacity: .6;
    }
  }
}

.c-iconlist {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  margin: 0;
  &__item {
    //height: 100%;
    & + .c-iconlist__item {
      margin-left: 16px;
      @media screen and (max-width: $breakPointSml + px){
        margin-left: 14px;
      }
    }
    a,
    span {
      display: block;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: opacity .2s ease-out;
      &:hover {
        opacity: .7;
      }
    }
    a[href=""] {
      opacity: .3;
      pointer-events: none;
    }
  }
}

.c-shopinfo {
  overflow: hidden;
  margin-top: 0;
  margin-bottom: 0;
  @media screen and (max-width: $breakPointSml + px){
    font-size: 13px;
    opacity: .6;
  }
  &__term {
    min-width: 83px;
    color: $white;
    float: left;
    &:after {
      content:'：';
      display: inline;
    }
  }
  &__def {

  }
}

.c-searchform {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 0;
  background-color: $white;
  padding: 14px 20px;
  line-height: 1.6;
  border: 1px solid $borderColor;
  &__input {
    border: none;
    border-bottom: 1px solid $borderColor;
    font-size: 16px;
    width: 180px;
    margin-right: 10px;
  }

  &__submit {
    border: 0px;
    padding: 2px 14px;
    border-radius: 2px!important;
    transition: opacity .2s ease-out;
    background-color: $borderColor;
    &:hover {
      opacity: .6;
    }
  }
}


.c-bottomnav {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  color: #777777;
  @media screen and (max-width: $breakPointTablet + px){
    flex-wrap: wrap;
  }
  @media screen and (max-width: $breakPointSml + px){
    display: block;
  }
  &__item {
    padding-right: 36px;
    width: 22.858%;
    @media screen and (max-width: $breakPointSml + px){
      padding-right: 0;
      width: 100%;
    }
    .newslettertext {
      margin-bottom: 0;
    }
    ul {
      li {
        padding-right: 1em;
        margin-bottom: 7px;
        line-height: 1.6;
        @media screen and (max-width: $breakPointSml + px){
          margin-bottom: 4px;
        }
        a {
          display: inline-block;
          transition: opacity .2s ease-out;
          &:hover {
            opacity: .6;
          }
        }


      }
    }
    &:nth-child(1) {
      //width: 31.42%;
      @media screen and (max-width: $breakPointTablet + px){
        //width: 40%;
      }
      @media screen and (max-width: $breakPointSml + px){
        width: 100%;
      }
      ul {
        //max-height: 170px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
        max-width: calc(100% - 15px);
        @media screen and (max-width: $breakPointSml + px){
          //max-height: 210px;
          max-width: initial;
          width: calc(100% + 12px);
        }
        li {
          max-width: 180px;
          @media screen and (max-width: $breakPointSml + px){
            width: calc(50% - 6px);
            margin-right: 12px;
          }
        }
      }
    }
    &:nth-child(2) {
      @media screen and (max-width: $breakPointTablet + px){
        width: 30%;
      }
      @media screen and (max-width: $breakPointSml + px){
        width: 100%;
      }
    }
    &:nth-child(3) {
      @media screen and (max-width: $breakPointTablet + px){
        width: 30%;
      }
      @media screen and (max-width: $breakPointSml + px){
        width: 100%;
      }
    }
    &:nth-child(4) {
      padding-right: 0;
      @media screen and (max-width: $breakPointTablet + px){
        width: 100%;
        max-width: 300px;
        margin-top: 48px;
      }
      @media screen and (max-width: $breakPointSml + px){
        width: 100%;
        max-width: initial;
        margin-top: 0;
      }
    }
    @media screen and (max-width: $breakPointSml + px){
      &:not(:first-child){
        border-top: 1px solid $borderColor;
      }
      &--toggle {
        .c-bottomnav__title {
          min-height: 64px;
          margin-bottom: 0;
          position: relative;
          &::before,
          &::after {
            content:'';
            display: block;
            background-color: $textBlack;
            position: absolute;
            top: 50%;
          }
          &::before {
            width: 15px;
            height: 1px;
            right: 12px;
          }
          &::after {
            width: 1px;
            height: 15px;
            right: 19px;
            margin-top: -7.5px;
            transition: opacity .2s ease-out;
          }
        }
        .c-bottomnav__content {
          display: none;
        }
        &.is-open {
          .c-bottomnav__title {
            &::after {
              opacity: 0;
            }
          }
        }
        &--last {
          + .c-bottomnav__item {
            .c-bottomnav__title {
              margin-top: 8px;
            }
          }
        }
      }
    }
    &:nth-child(4) {
      .c-bottomnav__content {
        @media screen and (max-width: $breakPointSml + px){
          padding-bottom: 0;
          font-size: 13px;
        }
        .c-btn {
          @media screen and (max-width: $breakPointSml + px){
            width: 100%;
            margin-top: 5px;
          }
        }
      }
    }
  }
  &__title {
    margin-top: 0;
    margin-bottom: 17px;
    font-size: 18px;
    @media screen and (max-width: $breakPointSml + px){
      margin-bottom: 7px;
      padding-top: 18px;
    }
  }
  &__content {
    font-size: 13px;
    @media screen and (min-width: $breakPointSml + 1 + px){
      display: block!important;
      height: auto!important;
    }
    @media screen and (max-width: $breakPointSml + px){
      font-size: 14px;
      padding-bottom: 30px;
    }
    ul {
      line-height: 2.1;
      @media screen and (max-width: $breakPointSml + px){
        margin-top: 0;
        margin-bottom: 0;
        line-height: 2;
      }
      li {
        @media screen and (max-width: $breakPointSml + px){
          //margin-bottom: 1px;
        }
      }
    }
    .c-btn {
      margin-top: 6px;
      min-width: initial;
      width: 100%;
      font-size: 14px;
      color: $textBaseColor;
    }
  }

}



.c-categorylist {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  width: calc(100% + 30px);
  margin-left: -15px;
  @media screen and (max-width: 1200 + px){
    max-width: 645px;
    margin-right: auto;
    margin-left: auto;
  }
  @media screen and (max-width: 700 + px){
    width: 100%;
    max-width: 500px;
    margin-right: auto;
    margin-left: auto;
  }
  @media screen and (max-width: $breakPointSml + px){
    justify-content: flex-start;
    width: 100%;
    margin-left: 0;
  }
  &__item {
    width: calc(16.6% - 25px);
    margin: 0 10px 20px;
    min-width: 160px;
    max-width: 185px;
    @media screen and (max-width: 1200 + px){
      min-width: 185px;
      margin: 0 15px 20px;
    }
    @media screen and (max-width: 700 + px){
      width: calc(50% - 15px);
      margin: 0 0 30px 15px;
      min-width: initial;
      max-width: initial;

    }
    @media screen and (max-width: $breakPointSml + px){
      width: calc(50% - 7.5px);
      margin: 0 0 17px 15px;
      min-width: initial;
      max-width: initial;
    }
    &:nth-child(6n-5){
      @media screen and (min-width: $breakPointSml + 1 + px){
        //margin-left: 0;
      }
    }
    &:nth-child(2n-1){
      @media screen and (max-width: 700 + px){
        margin-left: 0;
      }
      @media screen and (max-width: $breakPointSml + px){
        margin-left: 0;
      }
    }
    a {
      display: block;
      &:hover {
        img.bw {
          opacity: 0;
        }
      }
    }
  }
  &__imgbox {
    position: relative;
    .bw {
      position: absolute;
      top: 0;
      left: 0;
    }
    img.bw {
      transition: opacity 0.2s ease-out;
    }
  }
  &__metabox {
    padding-top: 10px;
    @media screen and (max-width: $breakPointSml + px){
      padding-top: 8px;
    }
  }
  &__title {
    margin: 0;
    font-size: 21px;
    text-align: center;
    @media screen and (max-width: $breakPointSml + px) {
      font-size: 18px;
    }
  }
}

.c-boxarrow {
  width: 9px;
  height: 9px;
  border-top: 1px solid $textBlack;
  border-right: 1px solid $textBlack;
  transform: rotate(45deg);
  @media screen and (max-width: $breakPointSml + px){
    width: 7px;
    height: 7px;
  }
}


.c-slidearrow {
  width: 44px;
  height: 44px;
  padding-right: 15px;
  border-radius: 0;
  background: none;
  transition: opacity .2s ease-out;
  @media screen and (max-width: $breakPointSml + px){
    width: 44px;
    height: 44px;
    padding-right: 11px;
  }
  &:after {
    content:'';
    display: block;
    width: 30px;
    height: 30px;
    border-radius: 0;
    background: none;
    border-top: 1px solid $black;
    border-right: 1px solid $black;
    transform: rotate(45deg);
    @media screen and (max-width: $breakPointSml + px){
      width: 20px;
      height: 20px;
    }
  }

  &--large {
    //width: 44px;
    //height: 64px;
  }
  &--backward {
    transform: rotate(180deg);
  }
  &:after {
    //display: none;//swiperの矢印を削除
  }
  &:hover {
    opacity: .6;
  }
}


.c-slidepagination {
  display: flex;
  justify-content: center;
  align-items: center;
  .swiper-pagination-bullet {
    width: 20px;
    height: 2px;
    background-color: #EAEAEA;
    border-radius: 0px;
    opacity: 1;
    margin: 0!important;
    transition: background .2s ease-out;
    @media screen and (max-width: $breakPointSml + px){
      width: 16px;
    }
    &-active,
    &:hover {
      background-color: $black;
    }
  }
  &.swiper-pagination-lock {
    display: none;
  }
}



.c-productslider {
  overflow: hidden;
  position: relative;

  &__wrapper {
    overflow: visible;
    @media screen and (max-width: $breakPointSml + px){
      margin-right: auto;
      margin-left: auto;
      padding-right: 22px!important;
      padding-left: 22px!important;
    }
  }
  &__item {
    @media screen and (min-width: $breakPointSml + 1 + px){
      width: calc(25% - 27px);
    }
    @media screen and (max-width: $breakPointSml + px){
      margin-right: 12px;
      max-width: 160px;
    }
  }
  &__button {
    width:36px;
    height: 36px;
    padding-right: 4px;
    border-radius: 50%;
    background-color: rgba($white, .8);
    opacity: 1;
    &:after {
      display: none;
    }
    &--prev {
      transform: rotate(180deg);
    }
  }
}


.c-detailslider {
  position: relative;
  .c-slidepagination {
    justify-content: flex-end;
    bottom: -12px;
    right: 52px;
    @media screen and (max-width: $breakPointSml + px){
      right: 20px;
    }
  }
}


.c-sectionheadline {
  position: relative;
  z-index: 2;
  margin-bottom: 43px;
  @media screen and (max-width: $breakPointSml + px){
    margin-bottom: 27px;
  }
  &__en {
    position: relative;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 44px;
    letter-spacing: 0.08em;
    &::after {
      content:'';
      display: block;
      width: 1px;
      height: 78px;
      position: absolute;
      top: calc(100% - 4px);
      left: 50%;
      margin-left: -0.5px;
      background-color: $textBlack;
      @media screen and (max-width: $breakPointSml + px){
        height: 42px;
      }
    }
    @media screen and (max-width: $breakPointSml + px){
      font-size: 36px;
    }
  }
}


.c-hr {
  border-color: $borderColor;
}

.c-designersslider {
  position: relative;
  overflow: hidden;
  &__item {
    width: 488px;
    max-width: 488px;
    min-width: 400px;
    width: 35.06vw;
    @media screen and (max-width: $breakPointSml + px) {
      max-width: initial;
      min-width: initial;
      width: auto;
    }
    a {
      display: block;
      transition: opacity .2s ease-out;
      &:hover {
        opacity: .7;
      }
    }
  }
  .c-designersslider__wrapper {
    overflow: visible;
    @media screen and (max-width: $breakPointSml + px) {
      padding-right: 32px;
      padding-left: 32px;
    }

  }
  .c-slidearrow {
    @media screen and (max-width: 499 + px){
      display: none;
    }
  }
}

.c-designersitem {
  &__imgbox {
    width: 100%;
    @media screen and (max-width: $breakPointSml + px){

    }
  }
  &__metabox {
    position: relative;
    margin-top: -16px;
    padding: 0 12px;
    @media screen and (max-width: $breakPointSml + px){
      padding: 0 10px;
    }
    p:last-child {
      margin-bottom: 0;
    }
    .inner {
      border-left: 1px solid $textBlack;
      padding-top: 30px;
      padding-left: 20px;
      @media screen and (max-width: $breakPointSml + px){
        padding-left: 15px;
      }
    }
  }
  &__title {
    margin-top: 0;
    margin-bottom: -3px;
    font-size: 27px;
    line-height: 1.3;
    @media screen and (max-width: $breakPointSml + px){
      font-size: 22px;
    }
  }
}

.c-ticker {
  &__item {
    text-align: center;
    height: 33px;
    padding: 0 60px;
    line-height: 1.6;
    @media screen and (max-width: $breakPointSml + px){
      height: 40px;
      padding: 0 28px;
    }
  }
  p {
    width: 100%;
    height: 100%;
    margin-bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: $breakPointSml + px){
      padding-top: 1px;
    }
  }
  &__inner {
    height: 100%;
    padding-top: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #555555;
    font-size: 10px;
    @media screen and (max-width: $breakPointSml + px){
      padding-top: 12px;
    }
  }
  a {
    display: inline-block;
    transition: opacity .2s ease-out;
    &:hover {
      opacity: .6;
    }
  }
  &__button {
    width: 36px;
    height: 40px;
    margin-top: -20px;
    padding-right: 0;
    opacity: 1;
    outline: none;
    transition: opacity .2s ease-out;
    &:hover {
      opacity: .5;
    }
    @media screen and (max-width: $breakPointSml + px){
      width: 28px;
    }
    &:after {
      display: none;
    }
    .c-boxarrow {
      border-color: $textColorGrey;
    }
    &--prev {
      left: 10px;
      transform: rotate(180deg);
      @media screen and (max-width: $breakPointSml + px){
        left: 4px;
      }
    }
    &--next {
      right: 10px;
      @media screen and (max-width: $breakPointSml + px){
        right: 4px;
      }
    }
  }
}


.c-breadcrumb {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 11px;
  color: rgba($black, .6);
  white-space: nowrap;
  @media screen and (max-width: $breakPointSml + px){
    font-size: 10px;
  }
  & + & {
    margin-top: 4px;
  }
  &__item {
    & + & {
      &:before {
        content:' - ';
        display: inline-block;
        margin: 0 5px;
      }
    }
    & > a, & > span {
      //opacity: .5;
      transition: opacity .2s ease-out;
    }
    &:last-child {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      a {
        pointer-events: none;
      }
    }
  }
  a {
    transition: opacity .2s ease-out;
    &:hover {
      opacity: 0.5;
    }
  }
  .c-arrowlink {
    &:hover {
      opacity: 1;
    }
    @media screen and (max-width: $breakPointSml + px){
      font-size: 12px;
    }
    &:after {
      @media screen and (max-width: $breakPointSml + px){
        width: 12px;
        height: 6px;
      }
    }
  }
}

.c-sort {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 12px;
  margin: 0;
  &__item {
    & + & {
      &:before {
        content:'・';
        display: inline-block;
      }
    }

    a {
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
    span.current {
      font-weight: bold;
    }
  }
}


.c-paging {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 0;
  font-size: 16px;
  @media screen and (max-width: $breakPointSml + px){
    width: 100%;
  }
  &__item {

    &:before {
      content: '';
      display: inline-block;
      margin: 0 19px;
      width: 1px;
      height: 16px;
      background-color: $black;
      position: relative;
      top: 2px;
    }
    &:first-child:before {
      display: none;
    }
    .c-arrowlink {
      @media screen and (max-width: $breakPointSml + px){
        font-size: 16px;
      }
    }
    .c-arrowlink:before,
    .c-arrowlink:after {
      margin-top: 9px;
      width: 24px;
      height: 6px;
    }
  }
}

.c-sidenav {
  &__item {
    border-top: 1px solid $borderColor;
    padding-top: 30px;
    padding-bottom: 30px;
    &--shopinfo {
      dt {
        font-weight: normal;
      }
      dd {
        font-size: 10px;
      }
    }
  }
  &__title {
    margin-top: 0;
    font-size: 13px;
    font-weight: bold;
  }
}
.c-linklist {
  letter-spacing: 0.05em;
  &__item {
    min-height: 38px;
    a {
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-height: 38px;
      line-height: 1.5;
      padding: 8px 0px;
    }
    .fa {
      margin-left: 1.5em;
    }
    &--isiconleft {
      a {
        justify-content: flex-start;
      }
      .fa {
        margin-left: 0;
        margin-right: 1em;
      }
    }
  }

}

.c-pagetitle {
  margin-bottom: 22px;
  @media screen and (max-width: $breakPointSml + px){
    margin-bottom: 20px;
  }
  &__en {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 28px;
    @media screen and (max-width: $breakPointSml + px){
      font-size: 21px;
    }
  }
  &__ja {
    letter-spacing: 0em;
    @media screen and (max-width: $breakPointSml + px){
      margin-top: 2px;
      font-size: 12px;
    }
  }
}

.c-headline {
  margin-bottom: 50px;
  &__en {
    margin-bottom: 0;
    font-size: 18px;
  }
  &__ja {
    margin-top: 2px;
    font-size: 14px;
    font-weight: normal;
  }
}


.c-groupchildlist {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  margin-top: 0;
  margin-bottom: 0;
  flex-wrap: wrap;
  font-size: 16px;
  @media screen and (max-width: $breakPointTablet-Sml + px){
    justify-content: flex-start;
  }
  @media screen and (max-width: $breakPointSml + px){

    font-size: 15px;
  }
  li {
    margin-bottom: 8px;
    margin-left: 28px;
    @media screen and (max-width: $breakPointTablet-Sml + px){
      margin-left: 0;
      margin-right: 20px;
      margin-bottom: 4px;
    }
    @media screen and (max-width: $breakPointSml + px){

    }
    &:last-child {
      //margin-left: 0;
    }
    a {
      display: inline-block;
      transition: opacity .2s ease-out;
      &:hover {
        opacity: .7;
      }
    }
  }

}

.c-guidelist {
  column-count: 3;
  column-gap: 56px;
  @media screen and (max-width: $breakPointSml + px){
    column-count: 1;
  }
  &__item {
    display: inline-block;
    width: 100%;
    margin-bottom: 50px;
  }
  &__term {
    padding-bottom: 6px;
    margin-bottom: 8px;
    border-bottom: 1px solid $borderColor;
    font-weight: normal;
  }
  &__def {
    font-size: 12px;
  }
}

.c-legallist {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
  &__item {
    &:before {
      content: ' | ';
      display: inline-block;
      margin: 0 5px;
    }
    &:first-child:before {
      display: none;
    }
  }
}

.c-dl {
  margin-bottom: 0;
  dt {
    line-height: 1.8;
    font-size: 18px;
    padding-top: 49px;
    margin-bottom: 20px;
    @media screen and (max-width: $breakPointSml + px){
      font-size: 16px;
      margin-bottom: 15px;
      padding-top: 43px;
    }
    &:first-child {
      //padding-top: 0;
      margin-top: -49px;
    }
  }
  dd {
    color: $textBaseColor;
    line-height: 1.8;
    padding-bottom: 52px;
    border-bottom: 1px solid $borderColor;
    @media screen and (max-width: $breakPointSml + px){
      padding-bottom: 42px;
    }
    ol, ul {
      margin: 0 0 1.4em
    }
  }
}


.c-culetweb {
  font-size: 11px;
  i {
    margin-right: 4px;
  }
}
.c-shoppingguide {
  font-size: 11px;
  //letter-spacing: ;
  i {
    margin-right: 4px;
    font-size: 15px;
  }
}

.c-showcart {
  margin-left: 15px;
  padding: 6px 13px;
  //background-color: $blackSecond;
  border-radius: 3px;
  color: $white!important;;
  line-height: 1;
  font-size: 11px;
  i {
    margin-right: 4px;
    font-size: 13px;
  }
}

.c-newslist {
  font-size: 12px;
  &__item {
    a {
      min-height: 49px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 10px 0;
      border-bottom: 1px dotted #ddd;
    }
  }
  &__date {
    margin-right: 36px;
    font-style: italic;
  }
}


.c-rankinglist {
  counter-reset: ranking;
  &__item {
    .c-card__imgbox {
      position: relative;
      counter-increment: ranking;
      &:before {
        content: counter(ranking);
        display: block;
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        //background: $blackThird;
        color: $white;
        width: 24px;
        height: 24px;
        text-align: center;
        font-size: 12px;
        padding-top: 2px;

      }
    }
  }
}

.c-btn {
  display: inline-block;
  padding: 10px 10px 9px;
  min-height: 44px;
  min-width: 200px;
  text-align: center;;
  border: 1px solid #AAAAAA;
  transition: background .2s ease-out, border .2s ease-out, color .2s ease-out;
  text-decoration: none!important;
  @media screen and (max-width: $breakPointSml + px){
    min-height: 42px;
    padding: 8px 10px 7px;
    min-width: 212px;
  }
  &.f-sans {
    padding: 10px;
    @media screen and (max-width: $breakPointSml + px){
      padding: 8px;
    }
  }
  &:hover {
    background-color: #c2c2c2;
    color: white!important;
    border-color: transparent;
  }
  &:visited {
    //color: $white;
  }
  &--arrow {
    &:after {
      //visibility: visible;
    }
  }
  &--strong {
    background-color: rgba($black, .06);
    font-size: 16px;
    color: $black;
    border: none;
    width: 100%;
  }
  &--secondary {
    width: 285px;
    height: 65px;
    margin-right: 0;
    margin-left: 0;
    font-size: 12px;
  }
  &[disabled] {
    pointer-events: none;
  }
}

.c-snslist {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 28px;
  &__item {
    margin-right: 10px;
    a {
      display: block;
      transition: opacity .2s ease-out;
      &:hover {
        @media screen and (min-width: $breakPointSml + 1 + px){
          opacity: .6;
        }
      }
    }
    img {
    }
  }
}

.c-share {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  &__title {
    margin-right: 11px;
    margin-bottom: 0;
    color: $black;
  }
  &__list {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 0;
    margin-bottom: 0;
  }
  &__item {
    margin-right: 8px;
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 60px;
      height: 28px;
      background-color: #F3F3F3;
      border-radius: 2px;
      transition: opacity .2s ease-out;
      &:hover {
        opacity: 0.7;
      }
    }
  }
}
.c-sharelist {

  &__item {

  }
}


.c-pagehead {
  position: relative;
  a {
    display: inline-block;
    transition: opacity .2s ease-out;
    &:hover {
      @media screen and (min-width: $breakPointSml + 1 + px){
        opacity: .6;
      }
    }
  }
  &__localnav {
    margin-top: 35px;
    @media screen and (max-width: $breakPointSml + px){
      margin-top: 15px;
    }
  }
}


.c-headline {
  &__title {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 30px;
    line-height: 1.4;
    letter-spacing: 0.02em;
    color: $black;
    @media screen and (max-width: $breakPointTablet + px){
      font-size: 40px;
    }
    @media screen and (max-width: $breakPointSml + px){
      font-size: 18px;
    }
  }
  &__hr {
    width: 30px;
    margin: 26px 0 0;
    border-color: $black;
    @media screen and (max-width: $breakPointSml + px){
      width: 20px;
      margin-top: 18px;
    }
  }
}


.c-cartitem {
  display: flex;
  justify-content:flex-start;
  align-items: center;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 0;
  padding-left: 0;
  &__head {
    width: 108px;
    text-align: left;
    @media screen and (max-width: $breakPointSml + px){
      width: 100px;
    }
  }
  &__body {
    width: 144px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
  }
  input, select {
    width: 100%;
    padding: 0 30px 0 12px;
    width: 144px;
    height: 32px;
    background-color: rgba($black, .08);
    outline: none;
    border: none;
    color: $textBaseColor!important;
    font-size: 14px;
    font-family: "Hiragino Sans", "ヒラギノ角ゴシック", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", Meiryo, Helvetica, Arial, system-ui, sans-serif;
    @media screen and (max-width: $breakPointSml + px){
      font-size: 16px;
    }
  }
  select {
    //font-size: 13px;
  }
  .input-prepend {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 0;
    position: relative;
  }
  .c-cartnumarrow {
    margin-left: 0;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
  }
}

//selectに擬似要素を設定できないのでオプションのinput要素の親に設定
.prduct-option {
  .c-cartitem__body {
    position: relative;
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      right: 9px;
      margin-top: -2px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 4px 4px 0 4px;
      border-color: $textBaseColor transparent transparent transparent;
    }
  }
}

.c-cartnumarrow {
  display: block;
  &__item{
    display: block;
    width: 24px;
    height: 16px;
    padding: 0;
    background-color: transparent;
    border: none;
    position: absolute;
    right: 0;
    &::before{
      content:'';
      display: block;
      width: 6px;
      height: 6px;
      border-top: 1px solid $textBaseColor;
      border-right: 1px solid $textBaseColor;
      position: relative;
      top: 50%;
      margin-top: 0;
      left: 50%;
      margin-left: -3px;
    }
    i {
      display: none;
    }
    &--up {
      top: 0;
      &:before {
        //top: 12px;
        transform: rotate(-45deg);
      }
    }
    &--down {
      bottom: 0;
      &:before {
        margin-top: -5px;
        transform: rotate(135deg);
      }
    }
  }

}


.c-datechip {
  display: block;
  min-width: 90px;
  min-height: 25px;
  margin-bottom: 0;
  padding-top: 1px;
  padding-bottom: 2px;
  text-align: center;
  color: $white;
  font-weight: 500;
  background-color: $black;
  @media screen and (max-width: $breakPointSml + px){
    min-width: 67px;
    padding-top: 3px;
    font-size: 10px;
  }
}


.c-card {
  position: relative;
  &[class*="new_mark_"] {
    &:before {
      content:'NEW';
      font-family: garamond-premier-pro, "Hiragino Mincho ProN", "YuMincho", "Yu Mincho", "MS PMincho", "Times New Roman", serif;
      font-weight: 400;
      font-style: normal;
      font-variant-ligatures: none;
      display: block;
      width: 44px;
      height: 20px;
      background-color: rgba($white, .8);
      position: absolute;
      top: 10px;
      left: 13px;
      z-index: 2;
      line-height: 1.2;
      text-align: center;
      padding-top: 3px;
      padding: 3px 0 0 1px;
      letter-spacing: 0.03em;
      @media screen and (max-width: $breakPointSml + px){
        padding-top: 3px;
        width: 36px;
        height: 18px;
        left: 8px;
        font-size: 11px;
      }
    }
  }
  &__imgbox {
    position: relative;
    overflow: hidden;
    padding-bottom: 125%;
    .inner {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    img {
      max-width: initial;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;

      /*
      width: 100%;
      transform: scale(1, 1);
      transform-origin: center;
      transition: transform .4s ease-out;
      */
    }

  }
  a {
    position: relative;
    display: block;
    transition: opacity .2s ease-out;
  }
  a:hover {
    opacity: .7;
    .c-card__imgbox {
      img {
        //transform: scale(1.05, 1.05);
      }
    }
  }
  &__metabox {
    padding: 12px 9px 0;
    @media screen and (max-width: $breakPointSml + px) {
      padding: 10px 3px 0;
    }
    p:last-child {
      margin-bottom: 0;
    }
  }
  &__subtitle {
    margin-bottom: -1px;
    font-size: 16px;
    color: $textBlack;
    line-height: 1.4;
    @media screen and (max-width: $breakPointSml + px) {
      margin-bottom: -2px;
    }
  }
  &__title {
    margin-top: 0;
    margin-bottom: 1px;
    font-size: 14px;
    font-weight: normal;
    color: $textColorGrey;
    @media screen and (max-width: $breakPointSml + px) {
      margin-bottom: -1px;
      font-size: 12px;
      letter-spacing: 0em;
    }
    img {
    }
    img[class^="new_mark"] {
      display: none!important;
    }
  }
  &__price {
    margin-bottom: 0;
    font-size: 13px;
    color: $textColorGrey;
    @media screen and (max-width: $breakPointSml + px) {
      font-size: 12px;
    }
    &--soldout {
      color: $red;
      font-weight: normal;
    }
  }
}


.c-news {
  a {
    display: block;
    transition: opacity .2s ease-out;
      &:hover {
        opacity: .7;
      }
  }
  &__imgbox {
    padding-top: 66.6%;
    position: relative;
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      max-width: initial;
      object-fit: cover;
      object-position: center;
    }
  }
  &__metabox {
    position: relative;
    padding-top: 11px;
    @media screen and (max-width: $breakPointSml + px){
      padding-top: 8px;
    }
  }
  &__date {
    margin-bottom: 2px;
    color: $textColorLight;
    @media screen and (max-width: $breakPointSml + px){
      margin-bottom: 0px;
      font-size: 11px;
    }
  }
  &__category {
    position: absolute;
    top: 11px;
    right: 0;
    margin-bottom: 0;
    color: $textColorLight;
    @media screen and (max-width: $breakPointSml + px){
      font-size: 10px;
    }
  }
  &__title {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 14px;
    color: $textBlack;
    line-height: 1.8;
  }
}


.c-featureslider {
  position: relative;
  overflow: hidden;

  &__wrapper {
    overflow: visible;

    &.l-inner {
      @media screen and (max-width: $breakPointTablet + px) {
        max-width: 480px;
        margin-right: auto;
        margin-left: auto;
      }
      @media screen and (max-width: $breakPointSml + px) {
        padding-right: 44px;
        padding-left: 43px;
      }
    }

  }
  .c-slidearrow {
    @media screen and (max-width: 499 + px){
      display: none;
    }
  }
}

.c-tile {
  a {
    display: block;
    position: relative;
    transition: opacity .2s ease-out;
    &:hover {
      opacity: .7;
    }
  }
  &__img {
    position: relative;
  }
  &__title {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 100%;
    padding: 0 20px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    color: $white;
    font-size: 24px;
    line-height: 1.3;
  }
}


.c-faqlist {
  letter-spacing: 0.025em;
  &__item {
    & + & {
      margin-top: 83px;
    }
  }
  &__term {
    position: relative;
    padding-left: 65px;
    font-size: 18px;
  }
  &__def {
    position: relative;
    margin-top: 12px;
    padding-top: 17px;
    padding-left: 65px;
    border-top: 1px solid #707070;
    figure {

    }
  }
  &__term:before, &__def:before {
    display: inline-block;
    font-size: 30px;
    position: absolute;
    top: 0;
    left: 0;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
  }
  &__term:before {
    content: 'Q.';
    top: -11px;

  }
  &__def:before {
    content:'A.';
    top: 3px;
  }
}


.c-pageheadlocalnav {
  font-weight: 500;
  &__item {
    & > a, & > span {
      font-size: 18px;
      font-weight: 600;
    }
  }
  &__child {
    margin-top: 0;

  }
  .is-comingsoon {
    //color: $disabled;
    pointer-events: none;
  }
}


.c-sectiontitle {
  margin-top:0;
  margin-bottom: 0;
  font-size: 56px;
  font-weight: 200;
  line-height: 1.4;

  @media screen and (max-width: $breakPointTablet + px){
    font-size: 44px;
  }
  @media screen and (max-width: $breakPointSml + px){
    font-size: 32px;
  }
}

.c-homenewslist {
  margin-bottom: 0;
  &__item {

    &:last-child {

    }
    a {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      padding-top: 8px;
      padding-bottom: 8px;
      transition: opacity .2s ease-out;
      @media screen and (max-width: $breakPointSml + px){
        padding-top: 5px;
        padding-bottom: 5px;
      }
      &:hover {
        @media screen and (min-width: $breakPointSml + 1 + px){
          opacity: .6;
        }
      }
    }
  }
  &__metabox {
    width: calc(100% - 172px);
    padding-top: 31px;
    padding-right: 28px;
    @media screen and (max-width: $breakPointSml + px){
      width: calc(100% - 104px);
      padding-top: 17px;
      padding-right: 12px;
    }
  }
  &__title {
    margin-top: 0;
    margin-bottom: 6px;
    font-size: 16px;
    @media screen and (max-width: $breakPointSml + px){
      margin-bottom: 4px;
      font-size: 14px;
    }
  }
  &__date {
    margin-bottom: 0;
    @media screen and (max-width: $breakPointSml + px){
      font-size: 11px;
    }
  }
  &__imgbox {
    width: 172px;
    @media screen and (max-width: $breakPointSml + px){
      width: 104px;
    }
  }
}


.c-boxshadow {
  position: relative;
  &:before {
    content:'';
    display: block;
    width: 100%;
    height: 65px;
    position: absolute;
    z-index: -1;
    bottom: 0;
    left: 0;
    border-radius: 100%;
    box-shadow: 0px 21px 30px 0px #eae5dc;
    mix-blend-mode: multiply;
  }
}


.c-product {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  @media screen and (max-width: $breakPointSml + px){
    display: block;
  }
  &__imgbox {
    max-width: 380px;
    width: 100%;
    position: relative;
    @media screen and (max-width: $breakPointSml + px){
      max-width: initial;
      width: 73.802%;
      margin-right: auto;
      margin-left: auto;
      margin-bottom: -17px;
    }
    figcaption {
      width: 130px;
      height: 130px;
      background-color: $black;
      border-radius: 50%;
      position: absolute;
      padding-top: 39px;
      top: -65px;
      left: -65px;
      //color: $light;
      text-align: center;
      font-size: 18px;
      line-height: 1.4;
      font-weight: 600;
      @media screen and (max-width: $breakPointSml + px){
        width: 90px;
        height: 90px;
        padding-top: 27px;
        top: -45px;
        left: -45px;
        font-size: 14px;
      }
    }
  }
  &__metabox {
    max-width: calc(444px + 30px);
    width: 100%;
    padding-left: 30px;
    position: relative;
    letter-spacing: 0.05em;
    @media screen and (max-width: $breakPointSml + px){
      max-width: 100%;
      padding-left: 0;
    }
    .title-en {
      margin-bottom: 25px;
      line-height: 1.2;
      .main {
        font-size: 30px;
        margin-bottom: 3px;
      }
      .sub {
        margin-bottom: 0;
        font-weight: 600;
      }
    }
    .title-ja {
      .main {
        margin-top: 0;
        margin-bottom: 9px;
        font-size: 18px;
        line-height: 1.333;

      }
      .sub {
        margin-bottom: 6px;
        font-weight: 600;
        line-height: 1.2;
        letter-spacing: 0;
        .price {
          font-size: 30px;
        }
      }
    }
    .c-btn {
      margin-top: 42px;
      font-size: 12px;
      height: 65px;
    }
  }
  &__metaboxhead {
    border-bottom: 1px solid $black;
  }
  &__metaboxbody {
    padding-top: 19px;
    & > p {
      line-height: 1.5;
    }
  }
  &__desclist {
    margin-top: 25px;
    dd.organic {
      margin-bottom: 26px;
    }
    dd.repair {
      .c-ingredientlist {
        margin-top: 12px;
      }
    }
  }
}

.c-belt {
  min-height: 25px;
  padding: 2px 2px 2px;
  text-align: center;
  font-size: 12px;

  border-radius: 25px;
  font-weight: 600;
  background-color: $black;
  //color: $bgColor;
  &--invert {
    color: $black;
  }
  &--ful {
    min-height: 50px;
    padding: 10px 5px;
  }
}

.c-ingredientlist {
  margin-top: 0;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  &__item {
    width: 100%;
    & + & {
      margin-top: 12px;
    }
    &--icon {
      width: 50%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: 0;
      @media screen and (max-width: $breakPointSml + px){
        flex-direction: column;
      }
      & + & {
        margin-top: 0;
      }
      &:nth-child(n+3) {
        margin-top: -13px;
      }
      .c-belt {
        min-width: 150px;
      }
      img {
        width: 60px;
        height: 60px;
      }
    }
  }
}


.c-arrowlink {
  display: inline-block;
  position: relative;
  font-size: 16px;
  color: $textBlack;
  transition: opacity .2s ease-out;
  @media screen and (max-width: $breakPointSml + px){
    //font-size: 14px;
  }
  &:visited, &:link {
    color: $textBlack;
  }
  &:before,
  &:after {
    content:'';
    display: block;
    width: 21px;
    height: 5px;
    background: url(../img/assets/icon_linkarrow.svg) center / contain no-repeat;
    float: right;
    margin-top: 11px;
    margin-left: 10px;
    transition: transform .2s ease-out;
    @media screen and (max-width: $breakPointSml + px){
      /*
      width: 19px;
      height: 10px;
      margin-top: 7px;
      margin-left: 8px;
      */
    }

  }
  &:before {
    display: none;
  }
  &:hover {
    @media screen and (min-width: $breakPointSml + 1 + px){
      opacity: .6;
    }
  }
  &--sub {
    &:visited, &:link {
    }
  }
  &--backward {
    &:before {
      display: block;
      float: left;
      background-image: url(../img/assets/icon_linkarrow_backward.svg);
      margin-right: 10px;
      margin-left: 0;
    }
    &:after {
      display: none;
    }
  }
  .prev {
    &:after {
      float: left;
      transform: rotate(180deg);
      margin-left: 0;
      margin-right: 8px;
    }
  }
}


.c-artistinfo {
  display:flex;
  justify-content: flex-start;
  align-items: stretch;
  @media screen and (max-width: $breakPointSml + px){
    display: block;
  }
  &__imgbox {
    width: 31.74%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    @media screen and (max-width: $breakPointSml + px){
      width: 100%;
      display: block;
    }
    figcaption {
      padding-top: 10px;
      line-height: 1.6;
    }
  }
  &__metabox {
    width: calc(100% - 31.74%);
    padding-left: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    @media screen and (max-width: $breakPointSml + px){
      width: 100%;
      display: block;
      padding-top: 18px;
      padding-left: 0;
    }
  }
  &__title {
    margin-top:0;
    margin-bottom: 18px;
    @media screen and (max-width: $breakPointSml + px){
      margin-bottom: 15px;
      font-size: 13px;
    }
  }
  &__desc {
    line-height: 1.6;
    @media screen and (max-width: $breakPointSml + px){
      font-size: 13px;
    }
  }
  a {
    text-decoration: underline;
    &:hover {
      @media screen and (min-width: $breakPointSml + px){
        text-decoration: none;
      }
    }
  }
}

.c-note {
  font-size: 13px;
  color: #777777;
  @media screen and (max-width: $breakPointSml + px){
    font-size: 12px;
  }
  a {
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
}

.c-upcomingexhibition {
  & + &  {
    margin-top: 32px;
  }
  &__inner {
    display:flex;
    justify-content: flex-start;
    align-items: center;
    @media screen and (max-width: $breakPointSml + px){
      display: block;
    }
  }
  &__tag {
    width: 87px;
    height: 23px;
    margin-bottom: 9px;
    line-height: 1.4;
    background-color: $white;
    border: 1px solid #333333;
    font-size: 13px;
    color: $black;
    letter-spacing: 0.0em;
    text-align: center;
    padding-top: 1px;
    @media screen and (max-width: $breakPointSml + px){
      width: 83px;
      height: 21px;
      font-size: 12px;
    }
  }
  &__imgbox {
    width: 48.80%;
    @media screen and (max-width: $breakPointSml + px){
      width: 100%;
    }
  }
  &__metabox {
    width: calc(100% - 48.80%);
    padding-left: 28px;
    @media screen and (max-width: $breakPointSml + px){
      width: 100%;
      padding-top: 0;
      padding-left: 0;
      margin-top: -10px;
      position: relative;
    }
  }
  &__title {
    margin-top:0;
    margin-bottom: 5px;
    font-size: 22px;
    letter-spacing: 0.05em;
    line-height: 1.4;
    @media screen and (max-width: $breakPointSml + px){
      font-size: 16px;
      line-height: 1.6;
    }
  }
  &__subtitle {
    color: #333;
    line-height: 1.6;
    @media screen and (max-width: $breakPointSml + px){
      margin-bottom: 8px;
      //font-size: 14px;
    }
  }
}

.c-pastcard {
  color: $black;
  font-size: 15px;
  letter-spacing: 0.03em;
  font-family: 'deuxpoissons-custom-medium';
  &__inner {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    min-height: 50px;
    padding-top: 11px;
    padding-bottom: 9px;
    padding-right: 30px;
    position: relative;
    @media screen and (max-width: $breakPointSml + px){
      display: block;
      padding-top: 14px;
      padding-bottom: 15px;
    }
    &.link:after {
      content:'';
      display:block;
      width: 10px;
      height: 10px;
      border-top: 1px solid $black;
      border-right: 1px solid $black;
      position: absolute;
      right: 14px;
      top: 50%;
      margin-top: -5px;
      transform: rotate(45deg);
      @media screen and (max-width: $breakPointSml + px){
        right: 11px;
      }
    }
  }
  a {
    transition: opacity .2s ease-out;
    &:hover {
      @media screen and (min-width: $breakPointSml + 1 + px){
        opacity: .6;
      }
    }
  }
  &__date {
    width: 27.134%;
    padding-right: 28px;
    margin-bottom: 0;
    min-width: 200px;
    @media screen and (max-width: $breakPointSml + px){
      width: 100%;
      margin-bottom: -2px;
      padding-right: 0;
      font-size: 13px;
    }
  }
  &__title {
    width: calc(100% - 27.134%);
    margin-bottom: 0;
    padding-top: 2px;
    padding-right: 28px;
    line-height: 1.4;
    @media screen and (max-width: $breakPointSml + px){
      width: 100%;
      padding-top: 0;
      padding-right: 0;
      letter-spacing: 0em;
    }
  }
}

.c-more {
  position: relative;
  display: inline-block;
  color: $black;
  font-size: 15px;
  font-weight: bold;
  font-family: 'deuxpoissons', 'Poppins', "Hiragino Sans", "ヒラギノ角ゴシック", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", Meiryo, Helvetica, Arial, system-ui, sans-serif;
  &__icon {
    width: 13px;
    height: 13px;
    display: inline-block;
    position: relative;
    margin-left: 12px;
    &:before, &:after {
      content:'';
      display: block;
      background-color: $black;
      position: absolute;
    }
    &:before {
      width: 100%;
      height: 1px;
      top: 6px;
      left: 0;
    }
    &:after {
      width: 1px;
      height: 100%;
      top: 0;
      left: 6px;
    }
  }

}

.c-langlist {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 13px;
  letter-spacing: 0.05em;
  @media screen and (max-width: $breakPointSml + px){
    font-size: 12px;
  }
  &__item {
    margin-right: 20px;
    &:last-child {
      margin-right: 0;
    }
    a {
      opacity: .7;
      transition: opacity .2s ease-out;
      &:hover {
        opacity: 1;
        text-decoration: underline;
      }
    }
    &.is-current a {
      opacity: 1;
      text-decoration: underline;
    }

  }
}




.wpcf7 {
  legend {
    margin-bottom: 11px;
    padding: 0 2px;
    font-weight: bold;
    font-size: 14px;
    .c-required {
      margin-left: 4px;
      position: relative;
      top: -6px;
    }
  }
  input, textarea {
    border: none;
    appearance: none;
    webkit-appearance: none;
    min-height: 48px;
    font-size: 16px;
    outline:none;
  }
  input[type="text"], input[type="email"], input[type="tel"] {
    max-width: 472px;
    width: 100%;
    padding: 14px 16px;
    border-radius: 2px;
    transition: border .2s ease-out;
  }
  input[type="text"], input[type="email"], input[type="tel"], textarea {
    &:focus {
      border-color: $black;
    }
    &:hover {
      border-color: $black;
    }
  }
  input[type="checkbox"],
  input[type="radio"] {
    appearance: none;
    webkit-appearance: none;
    min-height: 15px;
    width: 15px;
    min-height: 15px;
    width: 15px;
    border: 1px solid #aaa;
    border-radius: 2px;
    margin: 0px 10px 0 0;
    position: relative;
    top: 2px;
    &:after {
      content:'';
      display: block;
      width: 15px;
      height: 12px;
      background: url(../img/assets/icon_check.svg) center / contain no-repeat;
      position: absolute;
      top: 0;
      right: -4px;
      opacity: 0;
      transition: opacity .1s ease-out;
    }
    &:checked {
      border-color: #666666;
      &:after {
        opacity: 1;
      }
    }
  }
  textarea {
    width: 100%;
    padding: 16px;
  }
}

.c-formitem {
  position: relative;
  border: none;
  padding: 0;
  margin: 0;
  .wpcf7-form-control-wrap {
    display: block;
  }
}

.wpcf7-submit {
  display: block;
  border: none;
  appearance: none;
  -webkit-appearance: none;
  width: 277px;
  height: 64px;
  margin: 0 auto;
  color: $white;
  font-size: 16px;
  font-weight: bold;
  transition: background .2s ease-out, opacity .2s ease-out;
  cursor: pointer;
  font-family: 'deuxpoissons', "Hiragino Sans", "ヒラギノ角ゴシック", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", Meiryo, Helvetica, Arial, system-ui, sans-serif;
  &[disabled]{
    opacity: .5;
    pointer-events: none;
  }
  &:hover {
    background-color: $black;
  }
}
.c-required {
  position: relative;
  display: inline-block;

}
.c-required:after {
  content:'';
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: $black;
  border-radius: 50%;
}

.wpcf7-list-item {
  font-size: 15px;
}

.c-submenu {

  &__item {
    display: inline-block;
    & + & {
      &:before {
        content:'/';
        display: inline-block;
        margin: 0 3px 0 0;
      }
    }
  }
}

.js-inview {
  opacity: 0;
  transform: translateY(0px);
  transition: opacity 1s ease-in-out, transform 1s ease-out;

}
.js-inview.is-inview {
  opacity: 1;
  transform: translateY(0px);
}

.c-sml {
  font-size: 10px;
  font-size: 0.5em;
  vertical-align: text-top;
  letter-spacing: -0.05em;
}

.c-instagramlist {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  &__item {
    width: calc(33.3% - 6.66px);
    margin-right: 10px;
    margin-bottom: 10px;
    @media screen and (max-width: $breakPointSml + px){
      width: calc(33.3% - 2px);
      margin-right: 3px;
      margin-bottom: 3px;
    }


    &:nth-child(3n){
      margin-right: 0;
    }
  }
}


.c-buynow {
  &__modal {
    display:none;
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($black, .95);
  }
  &__modaloverlay {

  }
  &__modalclose {
    width: 45px;
    height: 45px;
    position: absolute;
    top: 20px;
    right: 20px;
    &:before, &:after {
      content:'';
      display: block;
      width: 100%;
      height: 2px;
      background-color: $black;
      position: absolute;
      top: 50%;
      left:0;
    }
    &:before {
      transform: rotate(40deg);
    }
    &:after {
      transform: rotate(-40deg);
    }
  }
  &__modalcontent {
    max-width: 800px;
    background-color: #fff;
    position: absolute;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    height: 400px;
    .inner {
      padding: 60px 40px 40px;
      max-width: 680px;
      margin-right: auto;
      margin-left: auto;
    }
    .title {
      font-size: 16px;
    }
    .storelist {
      margin-top: 60px;
      li {

        display: flex;
        justify-content: space-between;
        align-items: center;
        & + li {
          margin-top: 20px;
        }
        p {
          margin-bottom: 0;
        }
        .c-btn {
          margin-top: 0;
        }
      }
    }
  }

}



img[class*="new_mark_"] {
  display: none!important;
}

.c-newsletter {
  div[id*="formbox_screen_subscribe_"]{
    padding: 0!important;
  }
  div[class*="formbox-editor_"]{
    background-color: transparent!important;
  }
  div[class*="formbox-title_"]{
    padding: 0!important;
    display: none;
  }
  img {
   display: none;
  }
  div[class*="formbox-editor_"] fieldset{
    padding: 0!important;
  }
  //ラベル
  label {
    margin-top:16px!important;
    color: $textBaseColor;
    font-family: garamond-premier-pro, "Hiragino Mincho ProN", "YuMincho", "Yu Mincho", "MS PMincho", "Times New Roman", serif!important;
    font-weight: 400;
    font-style: normal;
    font-variant-ligatures: none;
  }
  //input text
  input[type="text"]{
    height: 40px;
    margin: 6px 0 0;
    border: none;
    padding: 0px 10px;
    font-size: 16px!important;
    background-color: $white;
  }

  //ボタン
  div[class*="formbox-button_"]{

    display: inline-block;
    padding: 10px 10px 9px;
    min-height: 44px;
    min-width: 200px;
    width: 100%;
    text-align: center;
    border: 1px solid #AAAAAA!important;
    font-size: 14px!important;
    padding-top: 13px!important;
    background-color: transparent!important;
    color: $textBaseColor!important;
    margin: 20px 0 0;
    transition: background .2s ease-out, border .2s ease-out, color .2s ease-out;
    &:hover {
      background-color: #c2c2c2!important;
      color: white !important;
      border-color: transparent!important;
    }
  }
}
