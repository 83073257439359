@charset "utf-8";
// CSS Remedy
*,::after,::before{box-sizing:border-box}html{line-sizing:normal}body{margin:0}h1{font-size:2rem}h2{font-size:1.5rem}h3{font-size:1.17rem}h4{font-size:1rem}h5{font-size:.83rem}h6{font-size:.67rem}h1{margin:.67em 0}pre{white-space:pre-wrap}hr{border-style:solid;border-width:1px 0 0;color:inherit;height:0;overflow:visible}audio,canvas,embed,iframe,object,svg,video{display:block;vertical-align:middle;max-width:100%}canvas,img,svg,video{height:auto}audio{width:100%}img{border-style:none}svg{overflow:hidden}article,aside,figcaption,figure,footer,header,hgroup,main,nav,section{display:block}[type=checkbox],[type=radio]{box-sizing:border-box;padding:0}img{vertical-align:middle;max-width:100%}

//================================
//base
//================================

body{
  font-family: "ヒラギノ明朝 Pro W3", Hiragino Mincho Pro, "游明朝", "YuMincho", "Yu Mincho", "MS PMincho", "Times New Roman", serif;
  font-size: 14px;
  color: $textBaseColor;
  line-height: 1.8;
  letter-spacing: 0.07em;
  -webkit-text-size-adjust: 100%;
  text-rendering:optimizeLegibility;
  -webkit-font-smoothing:antialiased;
  -moz-osx-font-smoothing:grayscale;
  @media screen and (max-width: $breakPointSml + px){

  }
}

.ua-android body {
  font-family: 'Noto Serif JP', "Hiragino Mincho ProN", "YuMincho", "Yu Mincho", "MS PMincho", "Times New Roman", serif;
}

.f-en-serif {
  font-family: garamond-premier-pro, "Hiragino Mincho ProN", "YuMincho", "Yu Mincho", "MS PMincho", "Times New Roman", serif;
  font-weight: 400;
  font-style: normal;
  font-variant-ligatures: none;
  &--italic {
    font-style: italic;
  }
}
.f-en-serifdisplay {
  font-family: garamond-premier-pro-display, serif;
  font-weight: 300;
  font-style: normal;
}
.f-sans {
  font-family: 'Source Sans Pro',"Hiragino Sans", "ヒラギノ角ゴシック", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", Meiryo, Helvetica, Arial, system-ui, sans-serif;
}
.f-ja-sans {
  font-family: "Hiragino Sans", "ヒラギノ角ゴシック", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", Meiryo, Helvetica, Arial, system-ui, sans-serif;
}

html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video, a{
  box-sizing: border-box;
}
main{
  display:block;
}
address {
  font-style:normal;
}
img{
  max-width:100%;
  height: auto;
}
button{
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  appearance: none;
}
h1, h2, h3, h4, h5, h6, dt {
  color: $textBlack;
  font-weight: normal;
  line-height: 1.6;
}
dd {
  margin-left: 0;
  line-height: 1.8;
}

ul, ol {
  list-style-type: none;
  padding: 0;
}
ul {
  margin-left: 0;//colormekit
}
figure {
  display: inline;
  margin: 0;
}
p {
  margin-top: 0;
  margin-bottom: 1em;
}
a:link { text-decoration: none;color: inherit;}
a:visited { color: inherit; }
a:hover { color: inherit; text-decoration: none;}
a:active { color: inherit; }
figure {
  display: block;
}
input[type="submit"],
input[type="button"] {
  border-radius: 0;
  -webkit-box-sizing: content-box;
  -webkit-appearance: button;
  appearance: button;
  border: none;
  box-sizing: border-box;
  cursor: pointer;
}
input[type="text"] {
  padding: 0;
  outline: none;
  background: none;
  -webkit-appearance: none;
  border-radius: 0px;
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  outline: none;
  background: transparent;
  border-radius: 0px;
}

//================================
//FONT
//================================
.f-en {
  //font-family: 'Inter', "Hiragino Sans", "ヒラギノ角ゴシック", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", Meiryo, Helvetica, Arial, system-ui, sans-serif;
}
.f-medium {
  //font-weight: 500;
}
.f-serif {
  //font-family: $serifFamily;
}

//================================
//PARTS
//================================
.show-sml {
  display: none;
}
@media screen and (max-width: $breakPointSml + px) {
  .hide-sml {
    display: none;
  }
  .show-sml {
    display: block;
  }
}
.align-r {
  text-align: right;
}
.align-c {
  text-align: center;
}
.video {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
}
.video iframe {
  position: absolute;
  top: 0;
  right: 0;
  width: 100% !important;
  height: 100% !important;
}
.clearfix:after {
  content: "";
  display: block;
  clear: both;
}

.l-main .editlink {
  display: block;
  width: 200px;
  margin: 40px auto 0;
  height: 40px;
  padding: 6px 20px;
  color: #111;
  border: 1px solid #111;
  border-radius: 20px;
  text-align: center;
  &:hover {
    opacity: .6;
  }
}
p:empty {
  margin: 0;
}
