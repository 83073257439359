@charset "utf-8";

.l-main[data-page="archive"]{
  padding-top: 103px;
  @media screen and (max-width: $breakPointSml + px){
    padding-top: 72px;
  }
  &.is-category {
    padding-top: 0;
  }
  padding-bottom: 0;
  .l-breadcrumb {
    position: absolute;
    top: 86px;
    max-width: calc(100% - 20px);
    @media screen and (max-width: $breakPointSml + px){
      top: 66px;
    }
  }
  .brandCap {
    display: none;
  }

  //カテゴリーの説明
  .l-categorypagehead {
    margin-top: -1px;
    margin-bottom: 96px;
    @media screen and (max-width: $breakPointSml + px){
      margin-bottom: 88px;
    }
  }

  //グループのタイトル + ナビゲーション
  .l-archivepagehead {
    margin-bottom: 63px;
  }

  //グループの説明
  .l-groupdesc {
    margin-top: -35px;
    margin-bottom: 96px;
    @media screen and (max-width: $breakPointSml + px){
      margin-bottom: 76px;
    }
  }

  //ソート
  .l-sortnav {
    margin-top: -30px;
    margin-bottom: 9px;
    @media screen and (max-width: $breakPointSml + px){
      margin-right: -8px;
    }
  }

  //該当商品なし
  .noitem {
    margin-top: 33px;
    @media screen and (max-width: $breakPointSml + px){
      margin-top: 20px;
    }
  }

  //リスト下のページング
  .l-paging {
    margin-top: 23px;
    margin-bottom: -18px;
    @media screen and (max-width: $breakPointSml + px){
      margin-bottom: -20px;
    }
    &:empty {
      margin-top: 0;
      margin-bottom: -45px;
      @media screen and (max-width: $breakPointSml + px){
        margin-bottom: -32px;
      }
    }
  }
}
