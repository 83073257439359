@charset "utf-8";

.sitefooter {
  padding: 96px 0 40px;
  background-color: $bgColor;
  letter-spacing: 0.05em;
  @media screen and (max-width: $breakPointSml + px){
    padding: 64px 0 35px;
  }
  &__inner {
    position: relative;
  }
  &__logo {
    margin-bottom: 55px;
    @media screen and (max-width: $breakPointSml + px){
      margin-bottom: 34px;
    }
    a {
      display: inline-block;
      transition: opacity .2s ease-out;
      &:hover {
        opacity: .7;
      }
    }
  }
  &__social {
    position: absolute;
    top: 0;
    right: 36px;
    @media screen and (max-width: $breakPointSml + px){
      position: static;
      margin-top: 33px;
    }
    .c-iconlist__item {
      & + .c-iconlist__item {
        margin-left: 20px;
        @media screen and (max-width: $breakPointSml + px){
          margin-left: 14px;
        }
      }
      img {
        @media screen and (max-width: $breakPointSml + px){
          width: 24px;
        }
      }
    }

  }
  &__copyright {
    margin-top: 83px;
    margin-bottom: 0;
    font-size: 11px;
    @media screen and (max-width: $breakPointSml + px){
      margin-top: 155px;
      opacity: .6;
    }
  }

}

