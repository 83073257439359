/*
CULET WEB にスタイルを引き継ぐ
既存のbodyに影響を与えないように class="cwnav" で囲んでスタイルを当てる。
*/

@charset "utf-8";

$breakPointSml: 600;
$breakPointTablet-Sml: 768;
$breakPointTablet: 1024;
$breakPointStandard: 1280;
$breakPointDesktop: 1440;
$breakPointLarge: 1920;

$black: #000000;
$textBlack: #111111;
$textBaseColor: #333333;
$textMenuColor: #444444;
$textColorGrey: #666666;
$textColorLight: #999999;
$white: #fff;
$red: #f00;

$headerPCHeight: 76px;
$headerMobileHeight: 56px;


$titleColor: #222222;

$borderColor: #D8D8D8;

$bgColor: #ECEBE9;//header footer
//$bgColorHome: #F9F9F9;//header footer
$bgGreyByImgTile: url(../img/assets/bg_designers_grey.jpg) left top / 4px 4px repeat;



$easeInOutQuad: cubic-bezier(0.45, 0, 0.55, 1);
$easeInOutCubic: cubic-bezier(0.65, 0, 0.35, 1);
$easeOutCubic: cubic-bezier(0.33, 1, 0.68, 1);
$easeInOutQuart: cubic-bezier(0.76, 0, 0.24, 1);

.ua-android body {
  font-family: 'Noto Serif JP', "Hiragino Mincho ProN", "YuMincho", "Yu Mincho", "MS PMincho", "Times New Roman", serif;
}

.cwnav {
  //bodyの内容を引き継ぐ
  font-family: "ヒラギノ明朝 Pro W3", Hiragino Mincho Pro, "游明朝", "YuMincho", "Yu Mincho", "MS PMincho", "Times New Roman", serif;
  font-size: 14px;
  color: $textBaseColor;
  line-height: 1.8;
  letter-spacing: 0.05em;
  -webkit-text-size-adjust: 100%;
  text-rendering:optimizeLegibility;
  -webkit-font-smoothing:antialiased;
  -moz-osx-font-smoothing:grayscale;
  &--header {
    //要素を class="cwnav" の親要素で囲っているため既存のstickyが効かなくなる。対応として
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }

  @import 'swiper.min';

  @import 'base';
  @import 'component';
  @import 'layout';

  @import 'header';
  @import 'footer';
  @import 'menu';

  @import 'base-content';


  @import 'detail';
  @import 'archive';
  @import 'pages';
  @import 'home';
  @import 'heroslider';
}


//@import 'loading';
