@charset "utf-8";

//================================
//PARTS
//================================
.siteheader {
  position: sticky;
  z-index: 11;
  top: 0;
  left: 0;
  width: 100%;
  height: $headerPCHeight;
  background-color: $white;
  border-bottom: 1px solid $borderColor;
  transition: opacity .3s ease-out, height .2s ease-out, background .2s ease-out;
  @media screen and (max-width: $breakPointSml + px){
    height: $headerMobileHeight;
  }
  &.is-home {
    border-color:transparent;
    body.is-scrolled &,
    body.is-floatnav-open & {
      border-color:$borderColor;
    }
  }
  &__inner {
    position: relative;
    z-index: 2;
    height: 100%;
    padding: 0 40px;
    padding: 0 50px;
    display: flex;
    //max-width: 1372px;
    //max-width: 1332px;
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;
    @media screen and (min-width: 1332 + 1 + px){
      //max-width: initial;
    }
    @media screen and (max-width: $breakPointTablet + px){
      padding: 0 20px;
    }
    @media screen and (max-width: $breakPointSml + px){
      padding: 0 0 0 20px;
    }
  }
  body.is-scrolled & .siteheader__logo {
    @media screen and (max-width: $breakPointSml + px){
      //transform: translateY(-4px);
    }
  }
  body.is-scrolled & .siteheader__menuicon {
    @media screen and (max-width: $breakPointSml + px){
      //transform: translateY(-4px);
    }
  }
  &__logo {
    display: inline-block;
    height: 100%;
    margin-top: 0;
    margin-bottom: 0;
    line-height: 1;
    font-size: 14px;
    transition: transform .2s ease-out;
    @media screen and (max-width: $breakPointSml + px){
      position: relative;
      //left: 37px;
    }
    .js-anchor {
      pointer-events: none;
      body.is-scrolled & {
        pointer-events: auto;
      }
    }
    a {
    display: flex;
    justify-content:center;
    align-items: center;
    transition: opacity .2s ease-out;
    width: 100%;
    height: 100%;
    @media screen and (max-width: $breakPointSml + px){
      //padding: 13px 26px;
    }
      &:hover {
        @media screen and (min-width: $breakPointSml + 1 + px){
          opacity: .6;
        }
      }
    }
    img {
      @media screen and (max-width: $breakPointTablet + px){
        width: 90px;
      }
      @media screen and (max-width: $breakPointSml + px){
        width: 90px;
      }
    }
  }
  &__gnav {
    @media screen and (max-width: $breakPointTablet + px){
      font-size: 12px;
      .c-gnav__item a {
        padding-top: 28px;
      }
      .c-gnav__item + .c-gnav__item {
        margin-left: 14px;
      }
    }
    @media screen and (max-width: $breakPointSml + px){
      display: none;
    }
  }
  &__nav {
    height: 100%;
    display: flex;
    justify-content:flex-end;
    align-items: center;
    @media screen and (max-width: $breakPointSml + px){
      right: 45px;
      position: absolute;
      top: 0;
      z-index: 2;
    }
    .l-shopnav {
      position: relative;
      height: 100%;
      @media screen and (max-width: $breakPointTablet + px){
        font-size: 10px;
        .l-shopnav__item + .l-shopnav__item {
          margin-left: 10px;
        }
        .l-shopnav__item--search {
          margin-right: 0;
        }
      }
      @media screen and (max-width: $breakPointSml + px){
        .l-shopnav__item {
          display: none;
        }
        .l-shopnav__item--search {
          display: block;
          margin-right: 10px;
        }
      }
      &:not(:first-child) {
        margin-left: 20px;
        padding-left: 20px;
        @media screen and (max-width: $breakPointSml + px){
          margin-left: 0;
          padding-left: 0;
        }
        &:before {
          content:'';
          display: block;
          width: 1px;
          height: 20px;
          background-color: #ffffff;
          position: absolute;
          top: 3px;
          left: 0;
          @media screen and (max-width: $breakPointSml + px){
            display: none;
          }
        }

      }
      &--sns {
        @media screen and (max-width: $breakPointSml + px){
          display: none;
        }
      }

    }
    .l-shopnav__item--search {
      .searchicon {
        cursor: pointer;
      }
      .searchform-container {
        position: absolute;
        top: calc(100% + 0px);
        right: -28px;
        display: none;
        @media screen and (max-width: $breakPointSml + px){
          right: -45px;
        }
      }
      &.is-open {
        .searchicon {
          opacity: .5;
        }
      }
    }

  }
  &__menuicon {
    display: none;
    @media screen and (max-width: $breakPointSml + px){
      display: block;
      z-index: 11;
      margin-right: 2px;
    }
  }
  &__floatnav {
    position: absolute;
    top: $headerPCHeight;
    left: 0;
    z-index: 1;
    @media screen and (max-width: $breakPointSml + px){
      display: none!important;
    }
  }
}

body.is-floatnav-open {
  .js-floatnav-open {
    //opacity: .5;
    //pointer-events: none;
  }
}

//menuicon
.c-menuicon {
  width: 54px;
  height: $headerMobileHeight;
  &__inner {
    position: relative;
    width: 20px;
    height: 10px;
    margin: auto;
  }
  &__bar {
    display: block;
    position: absolute;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: $black;
    transform-origin:center;
    transition: background .2s ease-out, transform .2s ease-out;
    body.is-menu-open & {
      //background-color: $light;
    }
    &:nth-of-type(1) {
      top: 0;
      body.is-menu-open & {
        //transform: translateY(5px) translateX(0px) rotate(35deg);
      }
    }
    &:nth-of-type(2) {
      bottom: 0;
      body.is-menu-open & {
        //transform: translateY(-5px) translateX(0px) rotate(-35deg);
      }
    }
  }
}
